// import React from 'react'
// import facebook from "../../public/facebook.png"
// import instagram from "../../public/instagram.webp"
// import google from "../../public/google.png"

// function Services() {
//   return (
//     <div className="services">
//       <div className="">
//         <h2 className="text-2xl font-bold text-gray-900">Services</h2>
//       </div>
//       <div className="services-content">
//         <div className="service">
//           <i className="fa fa-facebook"></i>
//           <div className="service-text">
//             <h2>Facebook Ads</h2>
//             <p>cial Push, our mission is to be the 
//               driving force behind your digital success, 
//               leveraging
//             </p>
//           </div>
//         </div>

//         <div className="service">
//           <i className="fa fa-instagram"></i>
//           <div className="service-text">
//             <h2>Facebook Ads</h2>
//             <p>cial Push, our mission is to be the 
//               driving force behind your digital success, 
//               leveraging
//             </p>
//           </div>
//         </div>

//         <div className="service">
//           <i className="fa fa-google"></i>
//           <div className="service-text">
//             <h2>Facebook Ads</h2>
//             <p>cial Push, our mission is to be the 
//               driving force behind your digital success, 
//               leveraging
//             </p>
//           </div>
//         </div>
//       </div>
//     </div>
//   )
// }

// export default Services

import { ArrowPathIcon, CloudArrowUpIcon, FingerPrintIcon, LockClosedIcon } from '@heroicons/react/24/outline'
import AOS from 'aos';
import 'aos/dist/aos.css';

AOS.init();
const features = [
  {
    name: 'Facebook Ads',
    description:
      "Our Facebook Ads service empowers your brand to connect with over two billion active users worldwide. We craft compelling ad campaigns that captivate audiences, sparking interest and driving engagement. ",
    icon: "fa fa-facebook",
  },
  {
    name: 'Instagram Ads',
    description:
      "Instagram, the visual powerhouse of social media, presents an unparalleled opportunity to showcase your brand's story in a captivating and immersive manner.",
    icon:"fa fa-instagram",
  },
  {
    name: 'Google Ads',
    description:
      "With Google Ads, we put your brand front and center where your potential customers are searching for products and services like yours.",
    icon:"fa fa-google",
  },
]

export default function Services() {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="text-base font-semibold leading-7 text-black-600">Grow faster</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            What we offer
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Our tailored strategies empower your brand's visibility, engagement, and conversions. Partner with us to unlock the full potential of social media and search engine platforms.
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name} className="relative pl-16" data-aos="fade-down">
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg">
                    <i className={feature.icon} style={{fontSize: `40px`}}></i>
                  </div>
                  {feature.name}
                </dt>
                <dd className="mt-2 text-base leading-7 text-gray-600">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
