import React from 'react'
import About from "../Pages/About";
import Services from './Services';
import ScheduleMeeting from './ScheduleMeeting';
import Facts from '../components/Facts';
import HowWeWork from '../components/HowWeWork';
import Footer from '../components/Footer';
import Accordion from '../components/Accordion';
import WhyChooseUs from '../components/WhyChooseUs';

import WorkWithUs from '../components/WorkWithUs';
import TechnicalSpecifications from '../components/TechnicalSpecifications';
import Contact from './Contact';



function Home() {
  return (
    <div>

        <section class="text-black banner-content">
          <div
            class="mx-auto max-w-screen-xl px-4 py-32 lg:flex lg:h-screen lg:items-center"
          >
            <div class="mx-auto max-w-3xl text-center">
              <h1
                class="text-black banner-text text-3xl font-extrabold sm:text-5xl"
                data-aos="zoom-in-down"
              >
                Unleash the Power of Digital Advertising.

                <span class="sm:block"> Increase Conversion. </span>
              </h1>

              <p class="mx-auto mt-4 max-w-xl sm:text-xl/relaxed" data-aos="fade-down">
                At Social Push, we believe that success in the
                digital age requires a strategic approach to 
                advertising. We are your dedicated partner, 
                committed to accelerating your brand's growth
                and visibility in the online world
              </p>

              <div class="mt-8 flex flex-wrap justify-center gap-4">
                <a
                  class="block bg-black w-full rounded border border-black-600 px-12 py-3 text-sm font-medium text-white"
                  href="/get-started"
                >
                  Get Started
                </a>

                <a
                  class="block w-full text-black rounded border border-black-600 px-12 py-3 text-sm font-medium focus:outline-none focus:ring active:bg-blue-500 sm:w-auto" style={{width: `100%`}}
                  href="/about"
                >
                  Learn More
                </a>
              </div>
            </div>
          </div>
        </section>

        <section>
            <WorkWithUs/>
        </section>

        <section>
            <Services />
        </section>

        

        <section>
            <TechnicalSpecifications />
        </section>

        <section>
            <HowWeWork />
        </section>

        <section>
            <WhyChooseUs />
        </section>

        <section>
            <ScheduleMeeting />
        </section>

        {/* <section>
            <Accordion />
        </section>       */}

        <section>
          <Contact />
        </section>


        <footer>
            <Footer />
        </footer>

    </div>
  )
}

export default Home