import Footer from "../components/Footer";
  export default function About() {
    return (
      <div style={{marginTop: "135px"}} className="relative isolate overflow-hidden bg-gray-900 py-24 sm:py-32">
        <div>
          <img
            src="https://images.unsplash.com/photo-1521737604893-d14cc237f11d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=focalpoint&fp-y=.8&w=2830&h=1500&q=80&blend=111827&sat=-100&exp=15&blend-mode=multiply"
            alt=""
            className="absolute inset-0 -z-10 h-full w-full object-cover object-right md:object-center"
          />
          <div
            className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl"
            aria-hidden="true"
          >
            
          </div>
            
          <div className="mx-auto max-w-7xl px-6 lg:px-8 about-us-container">
            <div className="mx-auto max-w-2xl lg:mx-0 about-us-text">
              <h2 className="text-4xl font-bold tracking-tight text-center text-white sm:text-6xl">About Us</h2>
              <p className="mt-6 text-lg leading-8 text-gray-300 text-center">
                Welcome to Social Push, your premier destination for cutting-edge social media marketing solutions. As a leading social media marketing agency, we specialize in elevating your brand's online presence through strategic Instagram, Facebook, and Google Ads campaigns.
              </p>

              <div className="text-gray-300 mt-5">
                
                <h2 className="text-4xl font-bold tracking-tight text-center text-white sm:text-4xl mt-5 mb-5">Why Choose Our Marketing Agency?</h2>
                
                <div>
                  <p className="mt-6 text-lg leading-8 text-center"><strong>Expertise</strong> Our team comprises skilled professionals well-versed in the intricacies of social media marketing. We stay updated on the latest trends and algorithms to ensure your campaigns are always ahead of the curve.</p>
                  <p className="mt-6 text-lg leading-8 text-center"><strong>Customization</strong> We recognize that every business is unique. That's why we tailor our strategies to match your brand identity, target audience, and marketing goals. Whether you're a startup or an established enterprise, our approach is always personalized.</p>
                  <p className="mt-6 text-lg leading-8 text-center"><strong>Proven Results</strong> With a track record of delivering exceptional outcomes, we take pride in driving quantifiable results for our clients. Increased followers, enhanced engagement, and higher conversion rates are just a few of the achievements you can expect.</p>
                  <p className="mt-6 text-lg leading-8 text-center"><strong>Comprehensive Services</strong> Our offerings extend beyond Instagram and Facebook. We're adept at harnessing the power of Google Ads to maximize your online reach and ensure your brand stands out in the digital crowd.</p>
                  <p className="mt-6 text-lg leading-8 text-center"><strong>ROI Focus</strong> We understand the importance of a healthy return on investment (ROI). Our strategies are designed not only to increase your online visibility but also to convert that visibility into tangible business growth.</p>
                  <p className="mt-6 text-lg leading-8 text-center"><strong>Transparency</strong> We believe in open communication and collaboration. Throughout the process, we provide detailed insights into your campaign's performance, allowing you to stay informed and make informed decisions.</p>
                  <p className="mt-6 text-lg leading-8 text-center"><strong>Satisfaction Guaranteed</strong> Your success is our success. We're dedicated to delivering services that exceed your expectations. With [Agency Name], you're partnering with a team that's as invested in your brand's success as you are.</p>
                  <p className="mt-6 text-lg leading-8 text-center">Elevate your brand's digital footprint with the power of social media marketing. Contact Social Push today to embark on a journey towards enhanced online visibility, increased engagement, and sustainable growth. Let's redefine your brand's story in the digital age.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    )
  }
  