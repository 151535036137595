import './App.css';
import React, {useState, useEffect} from "react";
import { BrowserRouter, Switch, Route, Routes, Link } from 'react-router-dom';
import NavBar from './components/NavBar';
import Home from './Pages/Home';
import About from './Pages/About';
import Contact from './Pages/Contact';
import ModeToggle from './components/ModeToggle';
import Meeting from "./components/Meeting"
import PuffLoader from "react-spinners/PuffLoader";
import AOS from 'aos';
import 'aos/dist/aos.css';


AOS.init();
function App() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }, []);

  return (
    <div className="App">
      {loading ? (
        <div className="loading">
          <PuffLoader size={70} color="black" loading={loading}/>
        </div>
      ) : (
        <header className="App-header">
        <BrowserRouter>
          <NavBar />
            <Routes>
                <Route index element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/meeting" element={<Meeting />} />
            </Routes>
          </BrowserRouter>
        </header>
      )
    }
    </div>
  );
}

export default App;
