import image1 from "../../public/smma-1.jpg";
import image2 from "../../public/smma-2.jpg";
import image3 from "../../public/smma-3.jpg";
import image4 from "../../public/smma-4.jpg";

const features = [
    { name: 'Platform Expertise', description: 'Instagram Marketing, Facebook Marketing, Google Ads Campaigns' },
    { name: 'Services Offered', description: 'Social Media Strategy Development, Ad Campaign Planning and Execution, Performance Analytics and Reporting' },
    { name: 'Expert Team', description: 'Social Media Strategists, Content Creators and Designers, Data Analysts, Advertising Specialists' },
    { name: 'Industry Trends Awareness', description: 'Keeping Abreast of Social Media Trends, Algorithm Changes and Updates Monitoring, Adaptive Strategies to Industry Shifts' },
    { name: 'Campaign Types', description: 'Awareness Campaigns, Engagement-Driven Campaigns ,Conversion-Focused Campaigns' },
    { name: 'ROI Tracking and Analysis', description: 'Conversion Tracking Implementation, Performance Metrics Monitoring, ROI Assessment and Optimization' },
  ]
  
  export default function TechnicalSpecifications() {
    return (
      <div className="bg-white">
        <div className="mx-auto grid max-w-2xl grid-cols-1 items-center gap-x-8 gap-y-16 px-4 py-24 sm:px-6 sm:py-32 lg:max-w-7xl lg:grid-cols-2 lg:px-8">
          <div data-aos="fade-down">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Technical Specifications</h2>
            <p className="mt-4 text-gray-500">
                Our technical prowess, combined with a team of experts, ensures your brand's message resonates, engages, and converts in the ever-evolving digital landscape.
            </p>
  
            <dl className="mt-16 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8" data-aos="fade-up">
              {features.map((feature) => (
                <div key={feature.name} className="border-t border-gray-200 pt-4">
                  <dt className="font-medium text-gray-900">{feature.name}</dt>
                  <dd className="mt-2 text-sm text-gray-500">{feature.description}</dd>
                </div>
              ))}
            </dl>
          </div>
          <div className="grid grid-cols-2 grid-rows-2 gap-4 sm:gap-6 lg:gap-8">
            <img
              src={image1}
              alt="Walnut card tray with white powder coated steel divider and 3 punchout holes."
              className="rounded-lg bg-gray-100"
              data-aos="flip-left" data-aos-duration="1500"
            />
            <img
              src={image2}
              alt="Top down view of walnut card tray with embedded magnets and card groove."
              className="rounded-lg bg-gray-100" data-aos="flip-down" data-aos-duration="1500"
            />
            <img
              src={image3}
              alt="Side of walnut card tray with card groove and recessed card area."
              className="rounded-lg bg-gray-100" data-aos="flip-up" data-aos-duration="1500"
            />
            <img
              src={image4}
              alt="Walnut card tray filled with cards and card angled in dedicated groove."
              className="rounded-lg bg-gray-100" data-aos="flip-right" data-aos-duration="1500"
            />
          </div>
        </div>
      </div>
    )
  }
  