/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/

import logo from "../../public/logo.png"
import Fact1 from "../../public/Fact2.png"

import AOS from 'aos';
import 'aos/dist/aos.css';

AOS.init();

const callouts = [
    {
      name: 'Increased Reach and Brand Awareness',
      description: 'Facebook, Instagram, and Google Ads provide businesses with powerful advertising platforms that can target specific demographics, interests, and behaviors of users',
      imageSrc: {Fact1},
      imageAlt: '',
      href: '#',
    },
    {
      name: 'Targeted Advertising and Customer Engagement',
      description: 'These advertising platforms offer advanced targeting options, allowing businesses to reach their ideal customers.',
      imageSrc: "https://static.wingify.com/gcp/uploads/2020/08/Customer-Engagement-Guide@2x.png",
      imageAlt: '',
      href: '#',
    },
    {
      name: 'Data-Driven Insights and Optimization',
      description: 'Facebook, Instagram, and Google Ads provide businesses with detailed analytics and performance metrics for their ad campaigns.',
      imageSrc: "https://www.lhpes.com/hubfs/top%20view%20of%20businessman%20hand%20working%20with%20modern%20technology%20and%20digital%20layer%20effect%20as%20business%20strategy%20concept-1.jpeg",
      imageAlt: '',
      href: '#',
    },
  ]
  
  export default function Facts() {
    return (
      <div className="">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="mx-auto max-w-2xl py-16 sm:py-24 lg:max-w-none lg:py-32">
            <h2 className="text-2xl font-bold text-gray-900">Facts</h2>
  
            <div className="mt-6 space-y-12 lg:grid lg:grid-cols-3 lg:gap-x-6 lg:space-y-0">
              {callouts.map((callout) => (
                <div key={callout.name} className="group relative" data-aos="flip-left">
                  <div className="relative h-80 w-full overflow-hidden rounded-lg bg-white sm:aspect-h-1 sm:aspect-w-2 lg:aspect-h-1 lg:aspect-w-1 group-hover:opacity-75 sm:h-64">
                    <img
                      src={Fact1}
                      className="h-full w-full object-cover object-center"
                    />

                  </div>
                  <h3 className="mt-6 text-sm text-gray-500">
                    <a href={callout.href}>
                      <span className="absolute inset-0" />
                      {callout.name}
                    </a>
                  </h3>
                  <p className="text-base font-semibold text-gray-900">{callout.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  }