import React from 'react'

function HowWeWork() {
  return (
    <div>
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="mx-auto max-w-2xl py-16 sm:py-24 lg:max-w-none lg:py-32">
            <h2 className="text-2xl font-bold text-gray-900" data-aos="fade-down">How We Work</h2>
        
                <ol class="relative mt-5 border-l border-gray-200 dark:border-gray-700">                  
                    <li class="mb-10 ml-4">
                        <div class="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
                        <p class="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">Step 1</p>
                        <h3 class="text-lg font-semibold text-gray-900 dark:text-white">Discovery and Consultation</h3>
                        <p class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">Explain how you start the collaboration by understanding your client's needs and goals. This might involve consultations, questionnaires, and in-depth discussions to gather essential information about their business and objectives.</p>
                    </li>
                    <li class="mb-10 ml-4">
                        <div class="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
                        <p class="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">Step 2</p>
                        <h3 class="text-lg font-semibold text-gray-900 dark:text-white">Strategy and Planning</h3>
                        <p class="text-base font-normal text-gray-500 dark:text-gray-400">Detail how you develop a customized strategy based on the information gathered during the discovery phase. Emphasize your approach to creating tailored solutions that align with each client's unique requirements.</p>
                    </li>
                    <li class="ml-4">
                        <div class="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
                        <p class="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">Step 3</p>
                        <h3 class="text-lg font-semibold text-gray-900 dark:text-white">Execution and Implementation</h3>
                        <p class="text-base font-normal text-gray-500 dark:text-gray-400">Describe how you execute the agreed-upon strategy. This could include content creation, ad campaigns, design work, social media management, or any other services you offer.</p>
                    </li>
                </ol>
            </div>
        </div>
    </div>
  )
}

export default HowWeWork